<template>
  <div class="drivers-and-reward">
    <section class="header">
      <div class="card">
        <div class="card-body">
          <div
            v-if="fetchingHighestLowestDriversComplianceReward"
            class="p-4 d-flex justify-content-center align-items-center"
          >
            <div class="spinner-border" role="status"></div>
          </div>
          <div v-else>
            <h6>HIGHEST EARNING DRIVER</h6>
            <h1>
              {{
                highestDriver ? highestDriver?.points_earned + ' points' : 'N/A'
              }}
            </h1>

            <p>
              <span>Driver</span>
              <span>{{
                highestDriver
                  ? highestDriver?.driver?.fname +
                    ' ' +
                    highestDriver?.driver?.lname
                  : 'N/A'
              }}</span>
            </p>
            <p>
              <span>Vehicle partner</span>
              <span>{{
                highestDriver
                  ? highestDriver?.driver?.partner?.company_name
                  : 'N/A'
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div
            v-if="fetchingHighestLowestDriversComplianceReward"
            class="p-4 d-flex justify-content-center align-items-center"
          >
            <div class="spinner-border" role="status"></div>
          </div>
          <div v-else>
            <h6>LOWEST EARNING DRIVER</h6>
            <h1>
              {{
                lowestDriver ? lowestDriver?.points_earned + ' points' : 'N/A'
              }}
            </h1>

            <p>
              <span>Driver</span>
              <span>{{
                lowestDriver
                  ? lowestDriver?.driver?.fname +
                    ' ' +
                    lowestDriver?.driver?.lname
                  : 'N/A'
              }}</span>
            </p>
            <p>
              <span>Vehicle partner</span>
              <span>{{
                lowestDriver
                  ? lowestDriver?.driver?.partner?.company_name
                  : 'N/A'
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="body">
      <div class="card">
        <!-- <div class="card-header justify-content-between">
          <div class="flex-row-reverse input-group input-group-flush d-flex">
            <input
              v-model.trim="search"
              class="form-control list-search"
              type="search"
              placeholder="Search"
            />
            <span class="border-0 input-group-text">
              <i class="fe fe-search"></i>
            </span>
          </div>
        </div> -->
        <!-- <div class=""> -->
        <b-table
          striped
          hover
          selectable
          responsive
          show-empty
          :items="filterDriversComplianceAccumulatedPoint"
          :fields="fields"
          :current-page="currentPage"
          :busy="fetchingDriversComplianceAccumulatedPoint"
          @row-clicked="viewRewardHistory"
        >
          <template #table-busy>
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <div class="spinner-border" role="status"></div>
              <p class="mt-2 text-center"><strong>Loading...</strong></p>
            </div>
          </template>
          <template #empty>
            <div class="no-results">
              <img src="@/assets/img/no-result-found.svg" />
              <p class="text-center text-secondary">No record found</p>
            </div>
          </template>
          <template #cell(serial)="data">
            <p class="my-0 font-weight-bold table-field">
              {{ data.index + 1 }}
            </p>
          </template>
          <template #cell(driver)="data">
            <p class="my-0 user-link table-field">
              {{ data.item.driver }}
            </p>
          </template>
          <template #cell(partner)="data">
            <p class="my-0 user-link table-field">
              {{ data.item.partner }}
            </p>
          </template>
          <template #cell(total)="data">
            <p class="my-0 font-weight-bold table-field">
              {{ data.item.total }}
            </p>
          </template>
          <template #cell(current)="data">
            <p class="my-0 font-weight-bold table-field">
              {{ data.item.current }}
            </p>
          </template>
        </b-table>
      </div>
      <div class="pageFooter" v-if="!fetchingEvents">
        <section>
          <p class="desc">
            Showing {{ paginationStartIndex }} - {{ paginationEndIndex }} of
            {{ totalRecords }}
          </p>
        </section>
        <section>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRecords"
            :per-page="perPage"
          ></b-pagination>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default {
  props: {
    eventRequestHandler: {
      type: Vue,
      required: false,
    },
  },
  created() {
    this.getRewardPointRate()
    this.getDriversComplianceAccumulatedPoints()
    this.getHighLowDriversComplianceAccumulatedPoints()

    this.eventRequestHandler.$on(
      'configureRewardPoint',
      this.configureRewardPointRate
    )

    this.eventRequestHandler.$on(
      'onCreateComplianceReward',
      this.updateComplianceReward
    )
  },
  data() {
    return {
      fields: [
        {
          key: 'serial',
          label: 'S/N',
        },
        {
          key: 'driver',
          label: 'Driver',
        },
        {
          key: 'partner',
          label: 'Vehicle Partner',
        },
        {
          key: 'total',
          label: 'Total Point',
        },
        {
          key: 'current',
          label: 'Current Point',
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      pageSize: 0,
      search: '',
      fetchingEvents: false,
    }
  },
  computed: {
    paginationStartIndex() {
      return this.currentPage * this.perPage - (this.perPage - 1)
    },

    paginationEndIndex() {
      return this.paginationStartIndex - 1 + this.pageSize
    },

    currentRewardPointRate() {
      return this.$store.state.rewardPoints.currentRewardPointRate
    },

    allDriversComplianceAccumulatedPoint() {
      return this.$store.state.rewardPoints.allDriversComplianceAccumulatedPoint
    },

    filterDriversComplianceAccumulatedPoint() {
      if (this.allDriversComplianceAccumulatedPoint)
        return this.allDriversComplianceAccumulatedPoint?.map((driver) => ({
          id: driver?.id,
          user_id: driver?.user_id,
          user_type: driver?.user_type,
          driver: driver?.driver?.fname + ' ' + driver?.driver?.lname,
          partner: driver?.driver?.partner?.company_name,
          total: driver?.points_earned,
          current: driver?.current_point,
        }))
      return null
    },

    fetchingDriversComplianceAccumulatedPoint() {
      return this.$store.state.rewardPoints
        .fetchingDriversComplianceAccumulatedPoint
    },

    highestLowestDriversComplianceReward() {
      return this.$store.state.rewardPoints.highestLowestDriversComplianceReward
    },

    fetchingHighestLowestDriversComplianceReward() {
      return this.$store.state.rewardPoints
        .fetchingHighestLowestDriversComplianceReward
    },

    highestDriver() {
      return this.highestLowestDriversComplianceReward?.highest
    },

    lowestDriver() {
      return this.highestLowestDriversComplianceReward?.lowest
    },
  },
  watch: {
    currentPage() {
      this.getDriversComplianceAccumulatedPoints()
    },
  },
  methods: {
    ...mapActions('rewardPoints', [
      'getCurrentRewardPointRate',
      'setCurrentRewardPointRate',
      'getAllDriversComplianceAccumulatedPoints',
      'getHighestLowestDriversComplianceAccumulatedPoints',
      'updateSingleComplianceReward',
    ]),

    viewRewardHistory(event) {
      this.$router.push({
        path: `/reward/drivers/history/${event.user_id}`,
      })
    },

    async getRewardPointRate() {
      try {
        const res = await this.getCurrentRewardPointRate()

        if ([200, 201].includes(res.status)) {

        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async configureRewardPointRate(rate) {
      try {
        const res = await this.setCurrentRewardPointRate({
          point: 1,
          value: rate,
          currency: 'NGN',
        })

        if ([200, 201].includes(res.status)) {
          this.$bvModal.hide('configure-confirmation-modal')
          this.$bvModal.show('configure-point-success-modal')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async getDriversComplianceAccumulatedPoints() {
      try {
        const res = await this.getAllDriversComplianceAccumulatedPoints()

        if ([200, 201].includes(res.status)) {

          this.currentPage = res.data.metadata.current_page
          this.totalRecords = res.data.metadata.total
          this.totalPages = res.data.metadata.total_pages
          this.pageSize = res.data.data.length
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async updateComplianceReward(data) {
      try {
        const res = await this.updateSingleComplianceReward(data)
        if ([200, 201].includes(res.status)) {
          // this.handleUpdating(index)
          // this.handleEdit(index)

          $bvModal.hide('create-reward-modal')
          $bvModal.show('create-reward-success-modal')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async getHighLowDriversComplianceAccumulatedPoints() {
      try {
        const res =
          await this.getHighestLowestDriversComplianceAccumulatedPoints()

        if ([200, 201].includes(res.status)) {

        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },
  },
  beforeDestroy() {
    this.eventRequestHandler.$off('configureRewardPoint')
    this.eventRequestHandler.$off('onCreateComplianceReward')
  },
}
</script>
<style lang="scss" scoped>
.drivers-and-reward {
  overflow: auto;
  & .header {
    display: flex;
    max-width: 80vw;
    width: 100%;
    gap: 0px 20px;

    & .card {
      max-width: 414px;
      width: 100%;

      & h6 {
        color: #6e717c;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 16px;
      }

      & h1 {
        color: #000005;
        font-size: 28px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 24px;
      }

      & p {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: -0.284px;

        & :first-child {
          line-height: 21px;
        }

        & :last-child {
          color: #4848ed;
          font-weight: 700;
          text-decoration-line: underline;
        }
      }
    }
  }

  & .toggle {
    position: relative;
    width: 48px;
    height: 24px;
    transition: all 200ms ease-in-out;

    & input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10px;
      opacity: 0;
      cursor: pointer;
    }

    & label {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f9fbfa;
      // border-radius: 50%;
      border-radius: 9999px;
      // ro
      // box-shadow: inn;
      // inset: 0px;
      transition: all 200ms ease-in-out;
      border: 1px solid #e5e5ea;

      &.active {
        background: #15cd70;
      }

      & span {
        position: inherit;
        width: 24px;
        height: 100%;
        background: #ffffff;
        border-radius: 9999px;
        box-shadow: grey;
        inset: 0px;
        transition: all 200ms ease-in-out;
        border: 1px solid #e5e5ea;

        &.active {
          transform: translateX(100%);
        }
      }
    }
  }

  & .body {
    & .card {
      & .user-link {
        color: #4848ed !important;
        font-weight: 500;
        letter-spacing: -0.284px;
        text-decoration-line: underline;
      }

      & .table-field {
        padding-top: 9px;
        padding-bottom: 9px;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0;
  img {
    height: 180px;
  }
}

.pageFooter {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 94px;

  & .desc {
    font-size: 14px;
    font-weight: 500;
    color: #131615;
  }
}

@media (max-width: 870px) {
  .drivers-and-reward {
    overflow: auto;
    & .header {
      display: grid;
      max-width: 100vw;
      .card {
        max-width: unset;
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .drivers-and-reward {
    overflow: auto;
    & .header {
      display: flex;
      max-width: 100vw;
    }
  }
}

@media (max-width: 610px) {
  .drivers-and-reward {
    overflow: auto;
    & .header {
      display: grid;
      max-width: 100vw;
      gap: 0px;
      // .card {
      //     max-width: unset;
      //     width: 100%;
      // }
    }
  }
}
</style>
